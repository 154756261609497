import React from 'react'
import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu'
import cx from 'clsx'
import { NavLink, useLocation } from 'react-router-dom'

import { IconDots } from '@tabler/icons-react'
import Button from '@components/Button'

import { SingleLinkObject } from '@msteams/components/Navigation/utils'

const LinkMenu = ({ links }: { links: SingleLinkObject[] }) => {
  const { pathname } = useLocation()
  return (
    <div>
      <Menu
        direction="bottom"
        position="initial"
        align="end"
        menuButton={
          <MenuButton>
            <Button className="p-0 h-9" color="white">
              <IconDots />
            </Button>
          </MenuButton>
        }
      >
        {links.map((link) => (
          <MenuItem
            key={link.to}
            className={cx(link.isActive(pathname) && '!bg-purple-50 !text-violet-700 !font-medium border-transparent')}
          >
            <NavLink to={link.to}>{link.label}</NavLink>
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

export default LinkMenu

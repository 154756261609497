import axios from 'axios'

import { API_ADDRESS } from '../constants'

import { LocalStorageKey } from '@msteams/types/common'

const token = localStorage.getItem(LocalStorageKey.mstoken)

const axiosInstance = axios.create({
  baseURL: API_ADDRESS,
  headers: {
    Authorization: token,
  },
  withCredentials: true,
})

axiosInstance.interceptors.request.use((req) => {
  const authToken = localStorage.getItem(LocalStorageKey.mstoken)
  const lsToken = authToken || undefined
  axiosInstance.defaults.headers.Authorization = lsToken
  req.headers.Authorization = lsToken
  return req
})

axiosInstance.interceptors.response.use(undefined, (err) => {
  if (err?.response?.status === 401) {
    localStorage.removeItem(LocalStorageKey.mstoken)
    axiosInstance.defaults.headers.Authorization = ''
  }

  throw err
})

export default axiosInstance
